<template>
    <div class="brownSupport">
        <Header></Header>
        <div class="brownSupport-banner">
            <img class="big-images" src="../../../assets/images/officialWebsite/other/other-banner.png" alt="">
        </div>
        <div class="brownSupport-title">
            <li :class="[ activeIndex == 1 ? 'title-pc active-pc' : 'title-pc']" @click="()=>{activeIndex=1}">PC端</li>
            <li :class="[ activeIndex == 2 ? 'title-phone active-phone' : 'title-phone']" @click="()=>{activeIndex=2}">移动端</li>
        </div>
        <div class="brownSupport-pcCon" v-show="activeIndex == 1">
            <h3>推荐使用Chrome、Edge浏览器</h3>
            <h4>Mac建议您使用Safari浏览器打开链接体验</h4>
            <img src="../../../assets/images/officialWebsite/other/other-PCwindow.png" alt="传奇云游戏-浏览器推荐">
            <a href="javascript:;">{{copyPCHttp}}</a>
            <div @click="copyLink(1)">复制链接</div>
        </div>
        <div class="brownSupport-phoneCon" v-show="activeIndex == 2">
            <div class="brownSupport-android">
                <section><i></i><span>Android下载</span></section>
                <div class="android-code" v-if="showAndroidQrCode" v-html="resource"></div>
                <div class="no-android" v-else>敬请期待</div>
                <div class="downLoad-word" v-if="showAndroidQrCode">
                    <p>扫描二维码下载 或 <a @click="directDown(loadHref)">直接下载</a> </p>
                    <p>传奇云游戏安卓版</p>
                </div>
            </div>
            <span class="brownSupport-solid"></span>
            <div class="brownSupport-ios">
                <section><i></i><span>手机浏览器</span></section>
                <a href="javascript:;">{{copyPhoneHttp}}</a>
                <div @click="copyLink(2)">复制链接</div>
                <p>iPhone手机建议您使用Safari浏览器打开链接，体验游戏玩法更佳！</p>
                <section><i></i><span>iOS下载</span></section>
                <div>敬请期待</div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    let base64 = require("js-base64").Base64;
    import helper from "@/utils/helper";
    export default {
        name: "NoSupport",
        data(){
            return {
                resource: '',
                showAndroidQrCode: true,
                copyPCHttp: '',
                copyPhoneHttp: '',
                loadHref: '',
                gameId: '',
                activeIndex: 1
            }
        },
        created() {
            this.gameId = this.$route.params.gameId
            let http = this.$route.params.copyHttp
            this.copyPCHttp = http.replace('/phonegame', '/game')
            this.copyPhoneHttp = http.replace('/game', '/phonegame')
        },
        mounted() {
            this.getCode()
        },
        methods: {
            copyLink(num) {
                //创建input标签
                let input = document.createElement('input')
                //将input的值设置为需要复制的内容
                num == 1 ? input.value = this.copyPCHttp : input.value = this.copyPhoneHttp
                //添加input标签
                document.body.appendChild(input)
                //选中input标签
                input.select()
                //执行复制
                document.execCommand('copy')
                //成功提示信息
                this.$store.commit('MESSAGE_TYPE',true)
                this.$store.commit('MESSAGE_TEXT','复制成功')
                //移除input标签
                document.body.removeChild(input)
            },
            getCode() {
                let packageName = ''
                switch (this.gameId) {
                    case "903":
                        packageName = 'cn.dygame.android.sdo.guilai';break;
                    case "900":
                        packageName = 'cn.dygame.android.sdo.chuanshi';break;
                    case "901":
                        packageName = 'cn.dygame.android.ff14';break;
                    case "600":
                        packageName = 'cn.dygame.android.sdo.gmys';break;
                    case "909":
                        packageName = 'cn.dygame.android.sdo.rexue';break;
                    default:
                        this.showAndroidQrCode = false
                        return
                }
                this.showAndroidQrCode = helper.isMobile() ? false : true;
                this.$API.getApkResourceInfo({
                    'package_name': packageName,
                }, {
                    "app-id": "cn.dygame.web"
                }).then((response)=>{
                    this.resource = base64.decode(response.data.data.qrCode)
                    this.loadHref = response.data.data.resource
                })
            },
            directDown(url) {
                window.location.href = url
            }
        }
    }
</script>

<style scoped lang="scss">
    .brownSupport {
        background: #ffffff;
        .brownSupport-banner {
            width: 100%;
            background: #2F333D;
            height: 480px;
            position: relative;
            .big-images {
                height: 480px;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
            }
        }
        .brownSupport-title {
            width: 1200px;
            margin: 73px auto 71px;
            border-bottom: 1px solid #FAD8A1;
            display: flex;
            justify-content: center;
            align-items: center;
            li {
                width: 144px;
                height: 90px;
                padding-left: 68px;
                font-size: 25px;
                font-weight: 500;
                color: #ADADB0;
                line-height: 90px;
                cursor: pointer;
                position: relative;
            }
            li:hover:after, .active-pc:after, .active-phone:after{
                content: "";
                position: absolute;
                left: 0;bottom: 0;
                width: 144px;height: 8px;
                background: url("../../../assets/images/officialWebsite/other/other-arrow.png") no-repeat center bottom;
                background-size: cover;
            }
            .title-pc {
                background: url("../../../assets/images/officialWebsite/other/other-pc.png") no-repeat left center;
                background-size: 44px 44px;
                margin-right: 200px;
            }
            .title-pc:hover {
                background: url("../../../assets/images/officialWebsite/other/other-pch.png") no-repeat left center;
                background-size: 44px 44px;
                color: #D5A561;
            }
            .title-phone {
                background: url("../../../assets/images/officialWebsite/other/other-phone.png") no-repeat -4px center;
                background-size: 44px 44px;
            }
            .title-phone:hover {
                background: url("../../../assets/images/officialWebsite/other/other-phoneh.png") no-repeat -4px center;
                background-size: 44px 44px;
                color: #D5A561;
            }
            .active-pc {
                background: url("../../../assets/images/officialWebsite/other/other-pch.png") no-repeat left center;
                background-size: 44px 44px;
                color: #D5A561;
            }
            .active-phone {
                background: url("../../../assets/images/officialWebsite/other/other-phoneh.png") no-repeat -4px center;
                background-size: 44px 44px;
                color: #D5A561;
            }
        }
        .brownSupport-pcCon {
            width: 420px;
            margin: 0 auto;
            h3 {
                height: 33px;
                font-size: 24px;
                font-weight: 500;
                color: #D5A561;
                line-height: 33px;
                text-align: center;
            }
            h4 {
                height: 20px;
                font-size: 18px;
                font-weight: 400;
                color: #606062;
                line-height: 20px;
                text-align: center;
                margin: 8px 0 32px;
            }
            img {
                width: 420px;
                height: 240px;
            }
            a {
                display: block;
                height: 25px;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                text-align: center;
                margin: 56px 0 31px;
                color: #ADADB0;
            }
            a:hover {
                color: #E58E60;
            }
            div {
                width: 140px;
                margin: 0 auto 140px;
                height: 48px;
                background: linear-gradient(180deg, #E8E7E5 0%, #D3CEC8 100%);
                border-radius: 4px;
                border: 1px solid #E6E6E6;
                font-size: 18px;
                font-weight: 400;
                color: #2F333D;
                line-height: 48px;
                text-align: center;
                cursor: pointer;
            }
            div:hover {
                color: #D5A561;
            }
        }
        .brownSupport-phoneCon {
            width: 1200px;
            margin: 0 auto 140px;
            display: flex;
            justify-content: center;
            .brownSupport-android {
                width: 316px;
                section {
                    width: 240px;
                    margin: 0 auto;
                    height: 64px;
                    background: #B3D21C;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                        width: 40px;
                        height: 40px;
                        background: url("../../../assets/images/officialWebsite/other/other-android.png") no-repeat center center;
                        background-size: cover;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 24px;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                }
                .android-code {
                    width: 300px;
                    height: 308px;
                    background: url("../../../assets/images/officialWebsite/scanCode_bg.png") no-repeat center;
                    background-size: cover;
                    margin: 24px auto 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-left: 2px;
                    svg {
                        width: 255px;
                        height: 255px;
                    }
                }
                .no-android {
                    width: 140px;
                    height: 48px;
                    margin: 40px auto 0;
                    text-align: center;
                    background: linear-gradient(180deg, #E6E6E6 2%, #E6E6E6 100%);
                    border-radius: 4px;
                    border: 1px solid #E6E6E6;
                    font-size: 18px;
                    font-weight: 400;
                    color: #606062;
                    line-height: 48px;
                }
                .downLoad-word {
                    height: 40px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #2F333D;
                    text-align: center;
                    p {
                        padding: 0;margin: 0;
                        height: 25px;
                        line-height: 25px;
                        a {
                            font-size: 16px;
                            color: #D69D4D;
                            font-weight: bolder;
                            cursor: pointer;
                        }
                    }
                }
            }
            .brownSupport-solid {
                width: 1px;
                height: 406px;
                background: #D8D8D8;
                margin: 0 72px 0 160px;
            }
            .brownSupport-ios {
                width: 452px;
                section {
                    width: 240px;
                    margin: 0 auto;
                    height: 64px;
                    background: #000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                        width: 40px;
                        height: 40px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 24px;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                }
                section:nth-of-type(1) {
                    i {
                        background: url("../../../assets/images/officialWebsite/other/other-brown.png") no-repeat center center;
                        background-size: cover;
                    }
                }
                section:nth-of-type(2) {
                    i {
                        background: url("../../../assets/images/officialWebsite/other/other-apple.png") no-repeat center center;
                        background-size: cover;
                    }
                }
                a {
                    display: block;
                    height: 25px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 25px;
                    text-align: center;
                    margin: 24px 0 31px;
                    color: #ADADB0;
                }
                a:hover {
                    color: #E58E60;

                }
                div {
                    width: 140px;
                    margin: 0 auto;
                    height: 48px;
                    background: linear-gradient(180deg, #FAD8A1 0%, #FAD8A1 100%);
                    border-radius: 4px;
                    border: 1px solid #FFFAF4;
                    font-size: 16px;
                    font-weight: 500;
                    color: #351B08;
                    line-height: 48px;
                    text-align: center;
                    cursor: pointer;
                }
                p {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #606062;
                    line-height: 20px;
                    text-align: center;
                    margin: 24px 0 68px;
                }
                div:nth-of-type(2) {
                    width: 140px;
                    height: 48px;
                    background: linear-gradient(180deg, #E6E6E6 2%, #E6E6E6 100%);
                    border-radius: 4px;
                    border: 1px solid #E6E6E6;
                    font-size: 18px;
                    font-weight: 400;
                    color: #606062;
                    line-height: 48px;
                    margin-top: 24px;
                }
            }
        }
    }

</style>
